<template>
  <div class="headPosiBox">
    <div class="installPrompt" v-if="isPluginInstalled">
      <div class="installPromptBox">
        <div class="conBox">
          <img class="RAGlogoImg" :src="require('@/assets/icon/rakumartandGoogle.svg')" alt="" />
          <span>Después de instalar el complemento de Google, puede buscar
            directamente los productos que desee en rakumart a través de
            imágenes. 🎉</span>
          <a href="https://chrome.google.com/webstore/detail/b%C3%BAsqueda-por-imagen-en-ra/ppgnkefpaocohpjnmldgigkpjccdikbe?utm_source=app-launcher&authuser=0"
            target="_blank" class="toGooGleShopBtn">
            <svg version="1.1" class="linkIcon" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 194.818 194.818"
              style="enable-background:new 0 0 194.818 194.818;" xml:space="preserve">
              <g>
                <path d="M185.818,2.161h-57.04c-4.971,0-9,4.029-9,9s4.029,9,9,9h35.312l-86.3,86.3c-3.515,3.515-3.515,9.213,0,12.728
		c1.758,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l86.3-86.3v35.313c0,4.971,4.029,9,9,9s9-4.029,9-9v-57.04
		C194.818,6.19,190.789,2.161,185.818,2.161z" />
                <path d="M149,77.201c-4.971,0-9,4.029-9,9v88.456H18v-122h93.778c4.971,0,9-4.029,9-9s-4.029-9-9-9H9c-4.971,0-9,4.029-9,9v140
		c0,4.971,4.029,9,9,9h140c4.971,0,9-4.029,9-9V86.201C158,81.23,153.971,77.201,149,77.201z" />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
            Instala la Extensión de Chrome
          </a>
          <div class="closeBtn" @click="closePromptClick">
            <img :src="require('@/assets/icon/closeWhite.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="headBox">
      <div class="logoBox">
        <router-link to="/" class="logoBox">
          <img style="  width:185px;height: 46px;" id="logo" @click="backHome"
            :src="require('@/assets/hometop/logo-rakumart-europe.svg')" alt="" /></router-link>
      </div>
      <div class="rightBox dip">
        <el-upload v-if="false" class="upImg" action="https://laravel.rakumart.es/api/common/uploadFile"
          :before-upload="befoUpload" :on-success="successup" :show-file-list="false" :http-request="httpup"
          accept=".jpg,.jpeg,.png">
          <div class="imageSearch dip">
            <img class="norImg" src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d013a772341.svg"
              alt="" />
            <img src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6705f7c3d5c0a.svg" alt=""
              class="hoverImg" />
            <span>{{ $fanyi("图像搜索") }}</span>
          </div>
        </el-upload>

        <div class="imgBtnList dip" v-if="false">
          <div class="imgBtnBox dip" @click="$fun.routerToPage('/centro-de-ayuda')">
            <img src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d01b5685e7c.svg" alt="" />
          </div>

          <div class="imgBtnBox dip cartBox" @click="openCart">
            <div class="cartNumBox" v-if="goodsNum > 0"></div>
            <img src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d01b752e26f.svg" alt="" />
          </div>
        </div>

        <div class="userMenuBox">
          <div class="flex acenter" v-if="userInfo">
            <div class="userHeaderBox dip">
              <img style="cursor: pointer" @click="$fun.routerToPage('/user/index')" svg :src="userInfo.head_img"
                v-if="userInfo.head_img" />
              <img style="cursor: pointer" @click="$fun.routerToPage('/user/index')" v-else
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d0471f295cd.svg" />
            </div>

            <div style="margin-left:16px" class="userNameBox dip">
              <el-popover placement="bottom" width="210" trigger="click" popper-class="ssfmenuBoxPopover"
                @show="hoverTip = true" @hide="hoverTip = false">
                <ul class="ssfmenuBox">
                  <li class="flex acenter" style="cursor: default;">
                    <div class=" dip iconBox userHead">
                      <img @click="$fun.routerToPage('/user/index')" svg :src="userInfo.head_img"
                        v-if="userInfo.head_img" />
                      <img @click="$fun.routerToPage('/user/index')" v-else
                        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d0471f295cd.svg" />
                    </div>

                    <div class="userInfoBox">
                      <div class="smallUserNameBox">
                        {{ userInfo.user_name }}
                      </div>
                      <div class="smallUserIdBox">
                        {{ userInfo.user_id }}
                      </div>
                    </div>
                  </li>
                  <div class="line"></div>
                  <li class="flex acenter userCenterBox" @click="
                    $fun.routerToPage('/user/index');
                  getUser();
                  ">
                    <div class="iconBox"></div>
                    <span>
                      {{ $fanyi("面板控制") }}
                    </span>
                  </li>
                  <div class="line"></div>
                  <li class="flex acenter outLoginBox" @click="loginOut">
                    <div class="iconBox"></div>
                    <span>
                      {{ $fanyi("退出登录") }}
                    </span>
                  </li>
                </ul>

                <div slot="reference" class="dip">
                  {{ userInfo.user_name }}
                  <img src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg" alt=""
                    class="rateIcon" :class="{ hoverIn: hoverTip }" />
                </div>
              </el-popover>
            </div>
          </div>

          <div class="loginBox" v-else>
            <el-button class="bgBlueBtn" @click="$store.commit('getshowRegisterDialog', true)">{{ $fanyi("免费注册")
              }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <transition name="opacity-slide">
      <div class="upLoadShadow" v-show="loading"></div>
    </transition>
    <!-- 图片上传中模块 -->
    <div class="fileLoadingBox dip fdcolmun" v-if="loading">
      <div class="loadingIcon" v-loading="true"></div>
      <div class="tipBox">
        {{ $fanyi("我们正在寻找您的产品，并为您选择最好的供应商。") }}
      </div>
      <div class="smallTipBox">
        {{ $fanyi("请稍等。") }}
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";

export default {
  data() {
    return {
      logininfo: localStorage.getItem("logininfo"),
      value: "",
      showQita: true, //显示其他
      closePrompt: document.cookie.indexOf("closePrompt") != -1,
      isPluginInstalled: false,
      imageId: "",
      tipagedata: "",
      hoverTip: false,
      loading: false,
    };
  },
  created() {
    // document.cookie = "closePrompt=true";
    // document.cookie = "closePrompt=; expires=Thu, 01 Jan 1970 00:00:00 GMT";//删除cookie
    // console.log(document.cookie.indexOf("closePrompt") != -1);
    // 防止搜索栏分类在跳转页面时因重新加载而清空
    this.value = this.$store.state.goodsListActiveId;
    this.$store.dispatch("goodsToCartNum");

    setTimeout(() => {
      this.isPluginInstalled =
        !document.getElementById("rakumart-plugin-dom") && !this.closePrompt;
    }, 300);
  },
  components: {},
  computed: {
    searchInfo() {
      return this.$store.state.searchInfo;
    },
    goodsNum() {
      return this.$store.state.cartCount;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    options() {
      return this.$store.state.goodsList;
    },
    token() {
      if (this.$store.state.userInfo) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() { },

  methods: {
    // 关闭推广
    closePromptClick() {
      this.isPluginInstalled = false;
      this.closePrompt = true;
      document.cookie = "closePrompt=true";
    },
    openCart() {
      if (localStorage.getItem("user_token")) {
        this.$fun.routerToPage("/cart");
      } else {
        this.$store.commit("getactivePage", "/cart");
        this.$store.commit("getshowLoginDialog", true); //打开登录弹窗
      }
    },
    getUser() {
      this.$api.EuropegetUserInfo().then((res) => {
        this.$store.commit("userData", res.data);
      });
    },

    // 返回首页
    backHome() {
      this.$store.commit("getsearchInfo", "");
    },
    // 跳转搜索页
    search() {
      let regtaobao = RegExp(/taobao/);
      let reg1688 = RegExp(/1688/);
      let regtmall = RegExp(/tmall/);
      let regrakumart = RegExp(/goods_id/);
      let id = "";
      var reg1 = /1688.com\/offer\/([0-9]+)/;
      var reg4 = /m\.1688\.com.*offerId=([0-9]{10,})/; //1688手机
      var reg2 = /[?|&]id=(\d{10,15})/;
      var reg3 = /[?|&]goods_id=(\d{10,15})/;
      // 判断是哪个网站的商品链接
      if (regtaobao.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "taobao");
        return false;
      } else if (
        reg1688.test(this.$store.state.searchInfo) &&
        (this.$store.state.searchInfo.indexOf("offer") != -1 ||
          this.$store.state.searchInfo.indexOf("offerId") != -1)
      ) {
        if (this.$store.state.searchInfo.match(reg1) == null) {
          id = this.$store.state.searchInfo.match(reg4)[1];
        } else {
          id = this.$store.state.searchInfo.match(reg1)[1];
        }
        this.$fun.toCommodityDetails(id, "1688");
        return false;
      } else if (regtmall.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "tmall");
        return false;
      } else if (regrakumart.test(this.$store.state.searchInfo)) {
        // return false;
        id = this.$store.state.searchInfo.match(reg3)[1];
        this.$fun.toCommodityDetails(id, "tmall");
      }
      // --------------------------------------------------
      // 设置分类选择框的已选中id
      let selId = this.value
        ? this.options.find((item) => item.chinese_name == this.value).id
        : "";
      // 如果是商品搜索页面并且路由上的id和选择器上的id不一致就重设路由
      if (
        this.$route.name == "CommoditySearch" &&
        selId != this.$route.query.group_id
      ) {
        //console.log("重设路由");
        this.$fun.routerToPage({
          query: {
            keyword: this.$store.state.searchInfo,
          },
        });
      }
      // 如果搜索的是同一词条就刷新页面
      if (this.$store.state.searchInfo == this.$route.query.keyword) {
        this.$router.go(0);
        return false;
      }
      // 如果关键词为空就不跳转
      if (!this.$store.state.searchInfo) {
        this.$message(this.$fanyi("请输入关键词搜索"));
        return false;
      }
      // 跳转
      this.$fun.routerToPage(
        "/CommoditySearch?keyword=" +
        this.$store.state.searchInfo +
        "&group_id=" +
        selId
      );
    },
    // 退出登录
    loginOut() {
      localStorage.removeItem("backgroundOperationID");
      sessionStorage.removeItem("backendPersonnel");
      localStorage.removeItem("user_token");
      sessionStorage.removeItem("user_id");

      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.routerToPage("/");
      } else {
        this.$router.go(0);
      }
    },
    befoUpload(file) {
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      //console.log(file.type);
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }

      this.loading = true;
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    // 图片上传事件
    httpup(file) {
      var _this = this;
      this.imageId = "";
      this.tipagedata = "";
      new Compressor(file.file, {
        quality: 0.2,
        success(result) {
          // console.log("skdfh", result); // 处理后的文件流BLOB
          // 以下为文件本地保存、下载查看压缩后的文件效果和大小，可省略
          // const blobUrl = window.URL.createObjectURL(result);
          // const a = document.createElement("a");
          // a.download = result.name;
          // a.style.display = "none";
          // a.href = blobUrl;
          // // 触发点击
          // document.body.appendChild(a);
          // a.click();
          // // 然后移除
          // document.body.removeChild(a);
          // 以下为将文件加载到formData中以便文件上传

          _this.blobToBase64(result).then((reader) => {
            _this.getImageId(reader);
          });
          let formData = new FormData();
          formData.append("file", result);
          //文件上传请求
          var xhr = new XMLHttpRequest();
          xhr.open(
            "POST",
            "https://laravel.rakumart.es/api/common/uploadFile",
            true
          );
          xhr.onload = () => {
            if (xhr.status === 200) {
              const res = JSON.parse(xhr.responseText);
              _this.tipagedata = res.data;
              _this.successup();
            } else {
              // console.log("An error occurred while uploading the file.");
            }
          };
          xhr.send(formData);
        },
        error(err) {
          //错误处理
          console.log(err.message);
        },
      });
    },

    //获取图片id
    getImageId(val) {
      let that = this;
      var img = new Image();
      img.src = val;
      img.crossOrigin = "Anonymous"; // 如果图片需要跨域访问，可以设置这个属性
      img.onload = function () {
        // 直接获取图片的原始宽高
        let width = img.width;
        let height = img.height;

        // 创建一个 canvas 元素
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");

        // 将图片绘制到 canvas 上
        ctx.drawImage(img, 0, 0, width, height);

        // 将 canvas 转换为 base64 格式
        var base64 = canvas.toDataURL("image/png"); // 可以改为 image/jpeg，image/webp 等其他格式
        let index = base64.indexOf("base64,");
        let base64String = base64.substr(index + 7, base64.length);

        // 构造上传参数
        let datas = {
          url: "/1/com.alibaba.fenxiao.crossborder/product.image.upload",
          uploadImageParam: JSON.stringify({
            imageBase64: base64String,
          }),
        };

        // 调用上传接口
        that.$api.uploadImagePic(datas).then((res) => {
          if (res.data.code != 200)
            return that.$message.error(that.$fanyi(res.data.message));
          that.imageId = res.data.result;
          that.successup();
        });
      };
    },

    // 图片上传成功事件
    successup() {
      console.log(this.imageId, this.tipagedata);
      if (this.imageId && this.tipagedata) {
        this.loading = false;
        this.$store.commit("getsearchImg", this.tipagedata);

        this.$fun.routerToPage(
          "/CommoditySearch?type=imgSearch&imgUrl=" +
          this.tipagedata +
          "&imageId=" +
          this.imageId,
          true
        );
      }
      return;
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headPosiBox {
  position: sticky;
  top: 0;
  z-index: 10;
  min-width: 1440px;
}

.installPrompt {
  $height: 54px;
  height: $height;
  width: 100%;

  .installPromptBox {
    height: $height;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 110;
    background-color: #4f6fff;

    .conBox {
      width: 1400px;
      height: $height;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .RAGlogoImg {
        width: 73px;
        height: 30px;
        margin-right: 13px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        color: white;
        margin-right: 13px;
        margin-bottom: 2px;
      }

      .toGooGleShopBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 3px;
        width: 256px;
        height: 27px;
        font-size: 13px;
        font-weight: 600;
        color: #1a73e8;
        background-color: #ffffff;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: #ffffff;
        transition: 0.3s;

        .linkIcon {
          width: 13px;
          height: 13px;
          fill: #1a73e8;
          margin-right: 5px;
          font-weight: bold;
          transition: 0.3s;
        }

        &:hover {
          color: white;
          background-color: #1a73e8;

          .linkIcon {
            fill: white;
          }
        }
      }

      .closeBtn {
        position: fixed;
        cursor: pointer;
        top: 17px;
        right: 20px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.headBox {
  height: 96px;
  @extend .dip;
  padding: 32px 48px;
  background-color: white;

  .logoBox {
    margin-right: auto;
  }

  .rightBox {
    gap: 40px;

    .imageSearch {
      padding: 12px 24px;
      gap: 10px;
      border-radius: 128px;
      background: #eef5fe;
      transition: 0.3s;
      position: relative;

      .hoverImg {
        display: none;
      }

      span {
        color: #1a73e8;
        font-size: 16px;
        font-weight: 700;
      }

      &:hover {
        background-color: #418df0;

        .norImg {
          display: none;
        }

        .hoverImg {
          display: block;
        }

        span {
          color: white;
        }
      }

      &:active {
        box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
          0px 1px 2px 0px rgba(16, 24, 40, 0.06),
          0px 0px 0px 4px rgba(26, 115, 232, 0.24);
      }
    }

    .imgBtnList {
      gap: 8px;

      .imgBtnBox {
        $size: 44px;
        width: $size;
        height: $size;
        cursor: pointer;
      }

      .cartBox {
        position: relative;

        .cartNumBox {
          position: absolute;
          top: 3px;
          right: 1px;
          background-color: #75ba2a;
          width: 10px;
          height: 10px;
          font-size: 12px;
          border-radius: 50%;
        }
      }
    }

    .userMenuBox {
      .userHeaderBox {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
        }

        color: var(--Gray-950, #0c111d);
      }

      .userNameBox {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        /* 155.556% */
        cursor: pointer;

        .rateIcon {
          transition: 0.3s;
          rotate: 180deg;
          margin-right: 10px;
        }

        .rateIcon.hoverIn {
          rotate: 0deg;
        }
      }

      .loginBox {
        gap: 16px;

        .el-button {
          min-width: 185px;
          font-size: 16px;
        }
      }
    }
  }
}

.ssfmenuBox {
  padding: 15px;

  li {
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;

    .iconBox {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }

    .iconBox.userHead {
      background: white;
      width: 40px;
      height: 40px;
      border-radius: 200px;
      border: 0.75px solid rgba($color: #000000, $alpha: 0.08);
      overflow: hidden;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .userInfoBox {
      .smallUserNameBox {
        color: var(--Gray-950, #0c111d);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
      }

      .smallUserIdBox {
        color: var(--Gray-500, #667085);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
      }
    }
  }

  // 用户中心
  li.userCenterBox {
    padding: 8px;

    .iconBox {
      background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f4d2324519b.svg");
    }
  }

  li.userCenterBox:hover {
    .iconBox {
      background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66f4d5d02138d.svg");
    }

    background: var(--Rakumart-50, #eef5fe);
    color: #1a73e8;
  }

  li.userCenterBox:active {
    background: #fff;
    color: #1a73e8;
  }

  // 退出登录
  li.outLoginBox {
    padding: 8px;

    .iconBox {
      background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d0549d64921.svg");
    }

    &:hover {
      .iconBox {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d11ef1a9795.svg");
      }

      background: #fef3f2;
      color: #f04438;
    }

    &:active {
      .iconBox {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d11ef1a9795.svg");
      }

      background: #fff;
      color: #f04438;
    }
  }

  .line {
    width: 177px;
    height: 1px;
    margin: 8px auto;
    background: #f2f4f7;
  }
}

.upLoadShadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 40;
}

.fileLoadingBox {
  position: fixed;
  z-index: 40;
  top: 37%;
  left: 50%;
  transform: translate(-50%);
  width: 749px;
  height: 230px;
  padding: 16px 22px;
  gap: 10px;
  background-color: white;
  border-radius: 16px;
  border: 2px solid var(--Gray-200, #eaecf0);
  background: #fff;
  /* Shadows/shadow-md */
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);

  .loadingIcon {
    width: 48px;
    height: 48px;

    /deep/.el-loading-spinner .path {
      stroke: #1a73e8;
    }
  }

  .tipBox {
    color: var(--Gray-950, #0c111d);
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    /* 155.556% */
  }

  .smallTipBox {
    color: var(--Gray-600, #475467);
    font-size: 13px;
    line-height: 20px;
    /* 142.857% */
  }
}
</style>
<style lang="scss">
// 打包之后会被覆盖，必须要用!important
.ssfmenuBoxPopover {
  padding: 0 !important;
  border-radius: 16px !important;
  border: none !important;
}
</style>
